<template>
  <div class="DataBase">
    <h1 class="title">Anticancer Database</h1>
    <el-menu class="Menu" :default-active="Index" mode="horizontal">
      <el-menu-item @click="showCancer" index="1"
        >Cancer Cell Database</el-menu-item
      >
      <el-menu-item @click="showNci" index="2">NCI60 Database</el-menu-item>
      <el-menu-item @click="showAnti" index="3"
        >Anti-cancer Target Database</el-menu-item
      >
    </el-menu>
    <CancerTable v-show="Cancer"></CancerTable>
    <NCITable v-show="Nci"></NCITable>
    <AntiTable v-show="Anti"></AntiTable>
  </div>
</template>

<script>
import cancerTable from "../components/cancerBigTable.vue";
import nciTable from "../components/nciBigTable.vue";
import antiTable from "../components/antiBigTable.vue";

export default {
  name: "contact",
  data() {
    return {
      Index: "1",
      Cancer: true,
      Nci: false,
      Anti: false,
    };
  },
  methods: {
    showCancer() {
      this.Cancer = true;
      this.Nci = false;
      this.Anti = false;
    },
    showNci() {
      this.Cancer = false;
      this.Nci = true;
      this.Anti = false;
    },
    showAnti() {
      this.Cancer = false;
      this.Nci = false;
      this.Anti = true;
    },
  },
  components: {
    CancerTable: cancerTable,
    NCITable: nciTable,
    AntiTable: antiTable,
  },
};
</script>

<style scoped>
.title-font {
  font-size: 28pt;
  font-weight: 700;
}
.text-font {
  font-size: 14pt;
  font-weight: 500;
}
.DataBase {
  display: flex;
  /* background: linear-gradient(var(--color1-light), var(--color1-dark)); */
  flex-direction: column;
  align-items: center;
  padding: 60px 0;
  min-height: 66vh;
}
.Menu {
  width: 800px;
  justify-content: space-around;
  background: rgba(0, 0, 0, 0);
  color: white;
}
.Menu .el-menu-item {
  color: white;
}

.column-flex {
  display: flex;
  flex-direction: column;
}
.row-flex {
  display: flex;
  flex-direction: row;
}
.title {
  font-size: 28pt;
  margin: 20px 0;
  padding: 0 10px;
  text-align: center;
  color: white;
}
</style>
