
<template>
  <div class="database-table">
    <div class="table-bar">
      <el-input
        class="search-input"
        placeholder="SARS-CoV-2，Cancer，COc1cc2[nH]c3c(C)nccc3c2cc1"
        input-style="height:50px"
        v-model="searchInput"
      >
        <template #append>
          <el-button @click="Search()"
            ><el-icon><search /></el-icon
          ></el-button>
        </template>
      </el-input>
      <el-pagination
        id="el-pagination"
        v-model:currentPage="currentPage"
        :page-sizes="[20, 50, 100, 200]"
        :page-size="pageSize"
        :pager-count="11"
        :page-count="pageCount"
        background
        :total="this.totalPage"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <div class="table">
      <el-table
        :data="dataBaseData.slice(0, pageSize)"
        stripe
        border
        style="width: 100%"
        @sort-change="handleSortChange"
        id="el-table"
      >
        <el-table-column
          sortable="custom"
          prop="CELL_NAME"
          label="CELL NAME"
          :resizable="false"
          width="120"
        />
        <el-table-column
          sortable="custom"
          prop="PANEL_NAME"
          label="PANEL NAME"
          :resizable="false"
          width="180"
        />
        <el-table-column :resizable="false" sortable prop="NSC" label="NSC" />
        <el-table-column
          sortable="custom"
          :resizable="false"
          prop="smiles"
          label="Smiles"
          width="280"
        />
        <el-table-column
          sortable="custom"
          :resizable="false"
          prop="CONCENTRATION"
          label="Concentration"
          width="180"
        />
        <el-table-column
          sortable="custom"
          :resizable="false"
          prop="CONCENTRATION_UNIT"
          label="Concentration Unit"
          width="180"
        />
        <el-table-column
          sortable="custom"
          :resizable="false"
          prop="AVERAGE_GIPRCNT"
          label="Average Giprcnt"
          width="180"
        />
        <el-table-column
          sortable="custom"
          :resizable="false"
          prop="STDDEV_GIPRCNT"
          label="Stddev Giprcnt"
          width="180"
        />
        <el-table-column
          sortable="custom"
          :resizable="false"
          prop="LOG_HI_CONCENTRATION"
          label="Log Hi Concentration"
          width="180"
        />
        <el-table-column
          sortable="custom"
          :resizable="false"
          prop="RELEASE_DATE"
          label="Release Date"
          width="180"
        />
        <!-- <el-table-column
          label="Detail"
          width="80"
          :resizable="false"
          class="detail-column"
        >
          <template #default="scope">
            <button
              class="detail-btn"
              size="small"
              @click="turnToDetail(scope.row['Detail Link'])"
            >
              Detail
            </button>
          </template>
        </el-table-column> -->
      </el-table>
    </div>
    <!-- <div class="loading-frame" v-show="isLoading">
      <div class="loading">
        <Loading></Loading>
      </div>
    </div> -->
  </div>
</template>

<script>
import { databaseApi } from "../api/index";
// // import loadingfrom "../components/loading.vue";

export default {
  props: ["data"],
  data() {
    return {
      dataBaseData: [],
      currentPage: 1,
      pageSize: 20,
      searchInput: "",
      totalPage: 0,
      // isLoading: true,
    };
  },
  components: {
    // Loading: false,
  },
  computed: {
    resultData() {
      return this.data;
    },
    //根据页面大小计算总页数
    pageCount() {
      return Math.ceil(this.totalPage / this.pageSize);
    },
    // searchData() {},
  },
  mounted() {
    this.getInitialData(1, 20);
  },
  methods: {
    handleClick(e) {
      console.log(e);
      //   this.$router.push({
      //     path: "/service/result-detail",
      //     query: {
      //       id: 123,
      //       obj: JSON.stringify({
      //         //将需要的信息传递给detail显示页面
      //         targetname: e.name,
      //         smilesInfo: this.smilesInfo[this.moleculeName],
      //         mode: this.mode,
      //         moleculeName: this.moleculeName,
      //       }),
      //     },
      //   });
    },
    handleSizeChange(val) {
      this.currentPage = 1;
      this.pageSize = val;
      if (this.searchInput == "")
        this.getInitialData(this.currentPage, this.pageSize);
      else this.getSearchData(this.currentPage, this.pageSize);
    },
    handleCurrentChange(val) {
      // 处理页面下标改变
      this.currentPage = Math.floor(val);
      if (this.searchInput == "")
        this.getInitialData(this.currentPage, this.pageSize);
      else this.getSearchData(this.currentPage, this.pageSize);
    },
    mounted() {
      this.getInitialData();
    },
    Search() {
      if (this.searchInput != "") this.getSearchData(1, this.pageSize);
      else {
        this.getInitialData(1, this.pageSize);
      }
    },
    getInitialData(current, pagesize) {
      this.$store.commit("startLoading");
      //根据当前页面以及页面大小进行分页请求数据库
      databaseApi
        .getnciData({
          from: (current - 1) * pagesize,
          size: pagesize,
          track_total_hits: true,
        })
        .then((res) => {
          this.totalPage = res["hits"]["total"]["value"];
          var hits = res["hits"]["hits"];
          this.dataBaseData = [];
          for (var i = 0; i < hits.length; i++) {
            this.dataBaseData.push(hits[i]["_source"]);
          }
          this.currentPage = current;
        })
        .catch((err) => {
          this.$store.commit("alertMsg", {
            msg: err,
            type: "error",
          });
        })
        .finally(() => {
          this.$store.commit("endLoading");
        });
    },
    getSearchData(current, pagesize) {
      this.$store.commit("startLoading");
      //根据搜索的内容进行分页请求
      databaseApi
        .getnciData({
          query: {
            multi_match: {
              query: this.searchInput,
              fields: [
                "CELL_NAME",
                "PANEL_NAME",
                "NSC",
                "smiles",
                "CONCENTRATION",
                "CONCENTRATION_UNIT",
                "AVERAGE_GIPRCNT",
                "STDDEV_GIPRCNT",
                "LOG_HI_CONCENTRATION",
                "RELEASE_DATE",
              ],
            },
          },
          from: (current - 1) * pagesize,
          size: pagesize,
          track_total_hits: true,
        })
        .then((res) => {
          if (res["status"] && res["status"] == 400) {
            this.$store.commit("alertMsg", {
              msg: "Load failed!",
              type: "error",
            });
          } else {
            this.totalPage = res["hits"]["total"]["value"];
            var hits = res["hits"]["hits"];
            this.dataBaseData = [];
            for (var i = 0; i < hits.length; i++) {
              this.dataBaseData.push(hits[i]["_source"]);
            }
            this.currentPage = current;
            this.$store.commit("alertMsg", {
              msg: "Load sucessfully!",
              type: "ok",
            });
          }
        })
        .catch((err) => {
          this.$store.commit("alertMsg", {
            msg: err,
            type: "error",
          });
        })
        .finally(() => {
          this.$store.commit("endLoading");
        });
    },
    /**默认的排序只针对当前页，所以要重写处理分页后的表格排序
     * 1. String： 按字典序
     * 2. Number： 按数指大小
     */
    handleSortChange(target) {
      if (target.prop == "name") {
        // 实际数据中只有targetName为String，所以单独处理
        if (target.order == "ascending") {
          this.resultData = this.resultData.sort((a, b) => {
            return a[target.prop] < b[target.prop] ? -1 : 1;
          });
        } else if (target.order == "descending") {
          this.resultData.sort((a, b) => {
            return a[target.prop] > b[target.prop] ? -1 : 1;
          });
        }
      } else {
        if (target.order == "ascending") {
          this.resultData = this.resultData.sort((a, b) => {
            return a[target.prop] - b[target.prop];
          });
        } else if (target.order == "descending") {
          this.resultData.sort((a, b) => {
            return b[target.prop] - a[target.prop];
          });
        }
      }
    },
    turnToDetail(Link) {
      window.open(Link, "_blank");
    },
  },
};
</script>

<style scoped>
.database-table {
  width: 80%;
  display: flex;
  flex-direction: column;
  z-index: 0;
}

.table-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  margin-top: 25px;
}

.table-bar .el-pagination {
  color: black;
}

.table {
  width: 100%;
  position: relative;
}

.search-input {
  width: 40%;
  margin-top: 25px;
  margin-bottom: 25px;
}

.search-input {
  font-size: 15px;
}

#el-pagination .el-pagination__total,
#el-pagination .el-pagination__jump {
  font-size: 15px;
  color: white;
  /* font-weight: li */
}

#el-pagination .el-pager .number.active {
  background: var(--color2-dark);
}

#el-pagination .el-pager .number:hover,
#el-pagination .btn-prev.hover,
#el-pagination .btn-next.hover {
  background: var(--color2-light);
  color: var(--color1-dark);
}

.el-table__header-wrapper .el-table__cell {
  font-size: 12pt;
  color: var(--color1-dark);
}

.el-table__body .el-table__row,
.el-table__body .el-table__row:hover {
  background: var(--color2-light);
  font-size: 12pt;
}

.el-table .cell {
  text-align: center;
}

.detail-btn {
  font-size: 12pt;
  border-radius: 5px;
  background: none;
  outline: none;
  padding: 5px;
  border: 1px solid var(--color2-dark);
  cursor: pointer;
}

.detail-btn:hover {
  background: var(--color2-light);
}
</style>